import React from "react";
import Page404 from "../components/Page404";
import Layout from "../components/Layout";
import MyHelmet from "../context/MyHelmet";

export default () => {
  return (
    <div>
      <Layout>
        <MyHelmet page="home" language="en" />
        <Page404 />
      </Layout>
    </div>
  );
};
